import revive_payload_client_DF7P7mOC1N from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.6_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__pocb26ofmm4hazltzyo5iyd6ya/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_xsxuu59RuI from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.6_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__pocb26ofmm4hazltzyo5iyd6ya/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_mkjCoU6hKi from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.6_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__pocb26ofmm4hazltzyo5iyd6ya/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_ooaEgquk1R from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.6_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__pocb26ofmm4hazltzyo5iyd6ya/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_lM0KXrYJhg from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.6_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__pocb26ofmm4hazltzyo5iyd6ya/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_pDguvHbrfr from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.6_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__pocb26ofmm4hazltzyo5iyd6ya/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_pXFeCnPZsl from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.6_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__pocb26ofmm4hazltzyo5iyd6ya/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_R9y3GJAee1 from "/app/node_modules/.pnpm/nuxt@3.15.1_@parcel+watcher@2.5.0_@types+node@22.10.6_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5__pocb26ofmm4hazltzyo5iyd6ya/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_N7gNWcaJR2 from "/app/node_modules/.pnpm/@storyblok+nuxt@6.2.2_vue@3.5.13_typescript@5.7.3_/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import plugin_MELCC5rzcd from "/app/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.30.1_vite@6.0.7_@types+node@22.10.6_jiti@2.4.2_sass_xp6bo7v4qz73nwlvvzyjnqhxuq/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_3kh9LP0OP3 from "/app/node_modules/.pnpm/nuxt-graphql-client@0.2.43_@parcel+watcher@2.5.0_@types+node@22.10.6_graphql-tag@2.12.6_graph_tc7hqs35g37n5xuazl5ljtg4ui/node_modules/nuxt-graphql-client/dist/runtime/plugin.js";
import session_client_SWtVx01rgL from "/app/node_modules/.pnpm/nuxt-auth-utils@0.5.7_magicast@0.3.5_rollup@4.30.1/node_modules/nuxt-auth-utils/dist/runtime/app/plugins/session.client.js";
import auth0_ZnwwTmFbx2 from "/app/plugins/auth0.ts";
import translate_56zPzW3R38 from "/app/plugins/translate.ts";
export default [
  revive_payload_client_DF7P7mOC1N,
  unhead_xsxuu59RuI,
  router_mkjCoU6hKi,
  payload_client_ooaEgquk1R,
  navigation_repaint_client_lM0KXrYJhg,
  check_outdated_build_client_pDguvHbrfr,
  chunk_reload_client_pXFeCnPZsl,
  components_plugin_KR1HBZs4kY,
  prefetch_client_R9y3GJAee1,
  plugin_N7gNWcaJR2,
  plugin_MELCC5rzcd,
  plugin_3kh9LP0OP3,
  session_client_SWtVx01rgL,
  auth0_ZnwwTmFbx2,
  translate_56zPzW3R38
]