<template>
    <div class="PriceListSelector">
        <select id="listino" placeholder="Listino" class="ms-2" v-model="listino">
            <option value="0" v-if="permissions.has_prices">EUR</option>
            <option value="1" v-if="permissions.has_prices_gbp">UK</option>
            <option value="2" v-if="permissions.has_prices_cny">CHN</option>
            <option value="3" v-if="permissions.has_prices_rub">RUS</option>
            <option value="4" v-if="permissions.has_prices_usd">USD</option>
        </select>
    </div>
</template>

<script setup lang="ts">

const permissions = usePermissions();

const listino = useCookie('price-list', { default: () => '0' })

if (!permissions.has_prices_gbp && listino.value == '1') {
    listino.value = '0'
}
if (!permissions.has_prices_cny && listino.value == '2') {
    listino.value = '0'
}
if (!permissions.has_prices_rub && listino.value == '3') {
    listino.value = '0'
}
if (!permissions.has_prices_usd && listino.value == '4') {
    listino.value = '0'
}

</script>
