export default defineNuxtRouteMiddleware((to, from) => {
    const { user } = useUserSession();
    const { has_tech_catalog } = usePermissions();

    if (to.path.match(/^\/products\/\w+\/pdf/)) {
        return;
    }

    if (["/user/logout", "/landing"].includes(to.path)) {
        return;
    }

    if (null == user.value) {
        return "/landing";
    }

    if (["/user/account", "/user/landing"].includes(to.path)) {
        return;
    }

    if (false == has_tech_catalog) {
        return "/user/landing";
    }
});
