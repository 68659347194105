<template>
    <div class="pdf">
        <slot />
    </div>
</template>

<style lang="scss">
:root {
    --bs-font-sans-serif: "Solido Book", sans-serif !important;
}

.pdf {
    font-family: "Solido Book", sans-serif !important;
}
</style>
